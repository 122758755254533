import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"

const ImpressumPage = () => (
  <div>
    <Seo title="Impressum - Altergott Studios" />
    <Layout>
      
      <section style={{
        padding: `4rem 0rem`,
      }}>
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Nikolai Altergott
          <br />
          POBE Studios
          <br />
          Lange &Auml;cker 1<br />
          71543 W&uuml;stenrot
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 1590 8167673
          <br />
          E-Mail: nikolai.altergott.na@gmail.com
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </section>
    </Layout>
  </div>
)

export default ImpressumPage
